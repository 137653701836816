import React from "react"

import Loader from "react-loader-spinner"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const ScreenLoader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        minHeight: "100%",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0,0,0, 0.4)",
        zIndex: 1000,
      }}
    >
      <Loader
        type="ThreeDots"
        color="#203760"
        height={100}
        width={100}
        timeout={30000}
      />
    </div>
  )
}

export default ScreenLoader
