/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import { Component, Fragment } from "react"

import PropTypes from "prop-types"
import Modal from "react-modal"

import LeftArrow from "../../assets/images/svg/left-arrow.svg"

const aspectRatioList = {
  "1:1": "100%",
  "16:9": "56.25%",
  "4:3": "75%",
  "3:2": "66.66%",
  "8:5": "62.5%",
}

const ThumbnailContainer = styled.div`
  display: ${props => (props.visible ? "block" : "none")};
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  ${({ aspectRatio }) => css`
    padding-top: ${aspectRatioList[aspectRatio]};
  `}
`
const thumbnailStyles = css`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const playerStyles = css`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;
`

const playIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ButtonPlay = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

const CloseModal = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    position: absolute;
    top: -30px;
    right: -30px;
    font-size: 25px;
    text-shadow: 0px 0px 5px black;
    border: none;
    z-index: 2;
    color: ${theme.colors.white};
    cursor: pointer;
    ${theme.queries.small} {
      display: none;
    }
  `}
`

const BackModal = styled(LeftArrow)`
  ${({ theme }) => css`
    display: none;
    ${theme.queries.small} {
      display: block;
      max-width: 25px;
      position: absolute;
      top: -210px;
      left: 20px;
      z-index: 10;
    }
  `}
`

const ModalOverlay = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 11;
    ${theme.queries.small} {
      background-color: black;
    }
  `}
`

const ModalContainer = styled.div`
  ${({ theme, aspectRatio }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    border: none;
    background: black;
    overflow: inherit;
    border-radius: 10px;
    outline: none;
    transform: translate(-50%, -50%);
    width: calc(100% - 60px);
    padding-top: ${aspectRatioList[aspectRatio]};
    max-width: 1200px;

    @media (min-width: 1300px) {
      width: 100%;
      padding: 0;
      height: 628px;
    }

    ${theme.queries.small} {
      width: 100%;
    }
  `}
`

class VideoPlayer extends Component {
  constructor() {
    super()
    this.state = {
      modalOpen: false,
    }
  }

  killModal = e => {
    if (e.key === "Escape") {
      this.setState({ modalOpen: false })
    }
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  componentDidMount() {
    window.addEventListener("keyup", this.killModal)
  }

  componentWillUnmount() {
    window.removeEventListener("onkeypress", this.killModal)
  }

  render() {
    const { aspectRatio, thumbnail, css, url, showThumbnail } = this.props

    const modalStyles = {
      overlay: {
        backgroundColor: "transparent",
        zIndex: 10,
      },
      content: {
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "transparent",
        border: "none",
      },
    }

    return (
      <ThumbnailContainer
        css={css}
        visible={showThumbnail}
        aspectRatio={aspectRatio}
      >
        <img src={thumbnail} css={thumbnailStyles} alt="Vídeo institucional" />
        <ButtonPlay
          onClick={() => this.setState({ modalOpen: true })}
        ></ButtonPlay>

        <Modal
          className="modalvideo"
          style={modalStyles}
          isOpen={this.state.modalOpen}
        >
          <ModalOverlay onClick={() => this.setState({ modalOpen: false })}>
            <BackModal onClick={() => this.setState({ modalOpen: false })} />
            <ModalContainer aspectRatio={aspectRatio}>
              <CloseModal onClick={() => this.setState({ modalOpen: false })}>
                ✖
              </CloseModal>
              <iframe
                width="100%"
                height="100%"
                css={playerStyles}
                src={url}
                title="modal"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sandbox="allow-same-origin allow-presentation allow-scripts"
              ></iframe>
            </ModalContainer>
          </ModalOverlay>
        </Modal>
      </ThumbnailContainer>
    )
  }
}

VideoPlayer.propTypes = {
  aspectRatio: PropTypes.string,
  thumbnail: PropTypes.string,
  showThumbnail: PropTypes.bool,
  css: PropTypes.object,
  url: PropTypes.string.isRequired,
}

VideoPlayer.defaultProps = {
  aspectRatio: "16:9",
}

export default VideoPlayer
