/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { Fragment, useState, createRef } from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"

import Button from "../Button"
import VideoPlayer from "../VideoPlayer"

import { Container } from "./styles"

import ErrorIcon from "../../assets/images/svg/error.svg"

const TopBannerAlert = ({ visible, handleClose }) => {
  const videoPlayer = createRef()

  const variants = {
    open: { y: 0 },
    closed: { y: "-100%" },
  }

  const runVideo = () => {
    videoPlayer.current.openModal()
    handleClose()
  }

  return (
    <Fragment>
      <AnimatePresence>
        <Container
          animate={visible ? "open" : "closed"}
          initial={{ y: "-100%" }}
          variants={variants}
          exit={{ opacity: "-100%" }}
        >
          <div>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <ErrorIcon />
              <div
                css={theme => css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <p>Transação não autorizada</p>
                <p>
                  Para entender o problema, clique no botão e veja o vídeo
                  explicativo.
                </p>
              </div>
            </div>

            <div
              css={theme => css`
                ${theme.queries.small} {
                  display: flex;
                  flex-direction: column-reverse;
                  margin-top: 10px;
                }
              `}
            >
              <motion.button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 1 }}
                onClick={handleClose}
                css={theme => css`
                  background: none;
                  border: none;
                  color: #8b8b8b;
                  cursor: pointer;
                  font-size: 16px;
                  font-weight: bold;
                  text-transform: uppercase;
                  outline: 0;

                  ${theme.queries.small} {
                    font-size: 14px;
                    margin: 15px 0;
                  }
                `}
              >
                Cancelar
              </motion.button>
              <Button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 1 }}
                onClick={() => runVideo()}
                uppercase
                css={theme => css`
                  background-color: #f8d050;
                  box-shadow: none;
                  cursor: pointer;
                  outline: 0;

                  ${theme.queries.medium} {
                    border-radius: 50px;
                    margin-left: 57px;
                    padding: 15px 45px;
                  }
                `}
              >
                Asssistir
              </Button>
            </div>
          </div>
        </Container>
      </AnimatePresence>

      <VideoPlayer
        ref={videoPlayer}
        url={"https://www.youtube.com/embed/vWyD6M5FyYI?autoplay=1"}
      />
    </Fragment>
  )
}

TopBannerAlert.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default TopBannerAlert
