/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

import BB from "../../../assets/images/icon-bb.png"
import Bradesco from "../../../assets/images/icon-bradesco.png"
import CEF from "../../../assets/images/icon-caixa.png"
import Itau from "../../../assets/images/icon-itau.png"
import Santander from "../../../assets/images/ic-santander.png"

const Container = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;

    p {
      color: ${theme.colors.blue};
      font-weight: bold;
    }

    div {
      display: flex;
      margin-top: 1rem;

      img {
        margin-right: 1rem;
        height: 2.5rem;
        width: auto;
      }
    }
  `}
`

const CardBrands = () => {
  return (
    <Container>
      <p>Bancos que trabalhamos</p>
      <div>
        <img src={BB} alt="" />
        <img src={Bradesco} alt="" />
        <img src={CEF} alt="" />
        <img src={Itau} alt="" />
        <img src={Santander} alt="" />
      </div>
    </Container>
  )
}

export default CardBrands
