import { css } from "@emotion/core"
import styled from "@emotion/styled"

const Card = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 22px 12px;
    border: 1px solid ${theme.colors.lightGrey};
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    &:first-of-type {
      border: 3px solid ${theme.colors.blue};
    }

    ${theme.queries.small} {
      width: 100%;
      margin: 15px 20px;
    }

    ${theme.queries.large} {
      width: 31%;
      margin: 0 14px 60px;
    }
  `}
`

// const NoResults = styled.h3`
//     ${({ theme }) => css`
//         color: ${theme.colors.mediumGrey};
//         display: block;
//         text-align: center;
//     `}
// `;

const CampaignImage = styled.div`
  ${({ image }) => css`
    display: block;
    width: 100%;
    height: 212px;
    border-radius: 10px;
    background-image: url(${image});
    background-size: cover;
    background-position: center;
  `}
`

const CampaignDetails = styled.div`
  ${({ theme }) => css`
    padding: 33px 20px 10px;
    position: relative;
    height: calc(100% - 212px);

    h3 {
      color: ${theme.colors.blue};
      font-size: 23px;
      line-height: 27px;
      margin-bottom: 13px;
      font-family: "Poppins", Arial, Helvetica, sans-serif !important;
      font-weight: bold;
      text-align: left;
    }

    p {
      color: ${theme.colors.mediumGrey};
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
  `}
`

export {Card,CampaignDetails,CampaignImage}