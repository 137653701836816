/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

import Container from "../../../Container"

import Church from "../../../../assets/images/svg/ic-church.svg"
import SocialWorks from "../../../../assets/images/svg/ic-social-works.svg"

const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.blue};
    font-size: 30px;
    text-align: center;
    margin-bottom: 70px;

    ${theme.queries.small} {
      font-size: 24px;
      margin: 60px auto;
      width: 200px;
      line-height: 120%;
    }
  `}
`

const Button = styled.button`
  ${({ theme }) => css`
    background: #00a1ff;
    color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 14px 24px rgba(0, 161, 255, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 344px;
    height: 111px;

    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;

    cursor: pointer;

    svg {
      margin-right: 20px;
    }

    ${theme.queries.large} {
      & + button {
        margin-left: 25px;
      }
    }

    ${theme.queries.small} {
      height: 90px;
      font-size: 20px;

      & + button {
        margin-top: 15px;
      }

      justify-content: flex-start;
      padding-left: 50px;
    }
  `}
`

const CustomContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding-top: 120px;
    padding-bottom: 120px;
    margin-bottom: 50px;
    position: relative;

    ${theme.queries.small} {
      padding-top: 20px;
      margin-left: -20px;
      margin-right: -20px;
    }
  `}
`

const Choose = ({ donateToWorks, donateToChurchs, translation }) => {
  return (
    <Wrapper>
      <Title>{translation.donate.title}</Title>
      <CustomContainer>
        <Button type="button" onClick={() => donateToChurchs()}>
          <Church />
          {translation.churchsName}
        </Button>
        <Button type="button" onClick={() => donateToWorks()}>
          <SocialWorks />
          {translation.socialWorks}
        </Button>
      </CustomContainer>
    </Wrapper>
  )
}

export default Choose
