import React, {useEffect} from "react"; 
import styled from "@emotion/styled";
import {  isMobile  } from "../../../../helpers";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  flex-shrink: 0;
  margin-bottom: 20px;
  margin-top: -30px;
`;

const Title = styled.p`
  color: #203760;
  font-size:  ${isMobile() ? 20: 30}px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 1.2px;
    text-align:  ${isMobile() ? "center" : "left"};
`;

const SubTitle = styled.span`
  color: #6e6e6e;
  font-size:  ${isMobile() ? 16: 20}px; 
  font-family: Poppins;
  line-height: 100%;
  letter-spacing: 1.2px;
  text-align:  ${isMobile() ? "center" : "left"};
`;

const HeaderPix = ({text, amount, otherAmount, translation}) => {


    useEffect(() => {
    if (amount !== null || otherAmount !== 0) {
      const handleUnload = (e) => {
        e.preventDefault();
        e.returnValue = "Você tem certeza de que deseja sair?";
      };

      window.addEventListener('beforeunload', handleUnload);
      return () => window.removeEventListener('beforeunload', handleUnload);
    }
  }, [amount, otherAmount]);

  return (
    <Container>
      <Title>{translation.paymentByPix}</Title>
      <SubTitle>
        {text}
      </SubTitle>
    </Container>
  );
};

export default HeaderPix;
