import React from "react"; 
import styled from "@emotion/styled"; 
import { isMobile } from "../../../../helpers"

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 197px;
  padding: 8px;
  flex-shrink: 0;
  margin-bottom: 49px;
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  background: #f0f0f0;
`;

const Title = styled.h1`
  width: 356px;
  flex-direction: column;
  justify-content: center;

  color: #203760;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.96px;
`;

const SubTitle = styled.h2`
  color: #203760;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: ${isMobile() ? "2rem" : "1rem"};
`;

const Description = styled.p`
  width: 399px;
  flex-direction: column;
  justify-content: center;

  color: var(--color-neutral-3, #919191);
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  letter-spacing: 0.64;
`;

const FooterPix = ({translation}) => {
  return (
    <Container>
      <Title>{translation.issuerInfo}</Title>
      <SubTitle>CNPJ</SubTitle>
      <Description>29.744.778/0001-97</Description>
      <SubTitle>{translation.companyName}</SubTitle>
      <Description>Igreja Universal do Reino de Deus</Description>
    </Container>
  );
};

export default FooterPix;
