import React from "react";
import { jsx, css, keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import Scan from "../../../../assets/images/svg/scan.svg";
import Pix from "../../../../assets/images/svg/pix.svg";
import Files from "../../../../assets/images/svg/files.svg";
import Smartphone from "../../../../assets/images/svg/smartphone.svg";
import CheckCircle from "../../../../assets/images/svg/check-circle.svg";

const Line = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin: 49px 0;
  opacity: 0.3;
  background: #919191;
`;

const LinePix = () => {
  return <Line></Line>;
};

export default LinePix;
