/** @jsx jsx */
import React from "react"
import { Router } from "@reach/router"
import { useEffect } from "react"
import { jsx } from "@emotion/core"
import { connect } from "react-redux"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Wizard from "../../components/Wizard"
import PrivateRoute from "../../components/PrivateRoute"
import ChooseStep from "../../components/pages/Login/ChooseStep"

import { setStep } from "../../state/app"
import { locales, routes } from "../../locales"
import { langNavigate } from "../../helpers"

import Payments from "../../templates/doar/pagamento"
import Confirmation from "../../templates/doar/confirmacao"
import Works from "../../templates/doar/obras-sociais"
import Churches from "../../templates/doar/igrejas"
import Campaigns from "../../templates/doar/igrejas/campanha"

const Donate = ({ dispatch, pageContext: translation }) => {
  // production
  const prefixPath = ""
  return (
    <Router>
      {locales.map((lang, index) => {
        const routeConfig = routes.filter(route => route.id === 5)[0]
        return (
          <PrivateRoute
            translation={translation}
            key={index}
            path={`${prefixPath}/${lang}${routeConfig.path[lang]}`}
            component={() => (
              <IndexPage
                key={index}
                dispatch={dispatch}
                translation={translation}
              />
            )}
          />
        )
      })}
      {locales.map((lang, index) => {
        const routeConfig = routes.filter(route => route.id === 6)[0]
        return (
          <PrivateRoute
            translation={translation}
            key={index}
            path={`${prefixPath}/${lang}${routeConfig.path[lang]}`}
            component={() => (
              <Payments
                key={index}
                dispatch={dispatch}
                translation={translation}
              />
            )}
          />
        )
      })}
      {locales.map((lang, index) => {
        const routeConfig = routes.filter(route => route.id === 7)[0]
        return (
          <PrivateRoute
            translation={translation}
            key={index}
            path={`${prefixPath}/${lang}${routeConfig.path[lang]}`}
            component={() => (
              <Confirmation
                key={index}
                dispatch={dispatch}
                translation={translation}
              />
            )}
          />
        )
      })}
      {locales.map((lang, index) => {
        const routeConfig = routes.filter(route => route.id === 15)[0]
        return (
          <PrivateRoute
            translation={translation}
            key={index}
            path={`${prefixPath}/${lang}${routeConfig.path[lang]}`}
            component={() => (
              <Confirmation
                key={index}
                dispatch={dispatch}
                translation={translation}
              />
            )}
          />
        )
      })}
      {locales.map((lang, index) => {
        const routeConfig = routes.filter(route => route.id === 8)[0]
        return (
          <PrivateRoute
            translation={translation}
            key={index}
            path={`${prefixPath}/${lang}${routeConfig.path[lang]}`}
            component={() => (
              <Works
                key={index}
                dispatch={dispatch}
                translation={translation}
              />
            )}
          />
        )
      })}
      {locales.map((lang, index) => {
        const routeConfig = routes.filter(route => route.id === 9)[0]
        return (
          <PrivateRoute
            translation={translation}
            key={index}
            path={`${prefixPath}/${lang}${routeConfig.path[lang]}`}
            component={() => (
              <Churches
                key={index}
                dispatch={dispatch}
                translation={translation}
              />
            )}
          />
        )
      })}
      {locales.map((lang, index) => {
        const routeConfig = routes.filter(route => route.id === 10)[0]
        return (
          <PrivateRoute
            translation={translation}
            key={index}
            path={`${prefixPath}/${lang}${routeConfig.path[lang]}`}
            component={() => (
              <Campaigns
                key={index}
                dispatch={dispatch}
                translation={translation}
              />
            )}
          />
        )
      })}
    </Router>
  )
}

const IndexPage = ({ dispatch, translation }) => {
  useEffect(() => {
    dispatch(setStep(1))
  }, [dispatch])

  const donateToWorks = () => {
    dispatch(setStep(2))
    langNavigate(8, translation.currentLang)
  }

  const donateToChurchs = () => {
    dispatch(setStep(2))
    langNavigate(9, translation.currentLang)
  }

  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={translation.pageName} />
      <Wizard translation={translation}>
        <ChooseStep
          donateToWorks={donateToWorks}
          donateToChurchs={donateToChurchs}
          translation={translation}
        />
      </Wizard>
    </Layout>
  )
}

export default connect(
  state => ({
    donationStep: state.app.donationStep,
  }),
  null
)(Donate)
