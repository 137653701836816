import styled from "@emotion/styled";
import {DebounceInput} from "react-debounce-input";
import {css, keyframes} from "@emotion/core";

const SearchInput = styled(DebounceInput)`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    padding: 30px 72px;
    border: none;
    border-radius: 10px;
    font-size: 28px;
    line-height: 33px;
    box-shadow: ${theme.shadows.default};
    margin-bottom: 67px;

    ${theme.queries.small} {
      font-size: 18pt;
      padding: 17px 30px;
    }
  `}
`

const SearchButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background: none;
    right: 70px;
    bottom: 50%;
    position: absolute;
    transform: translateY(50%);
    cursor: pointer;

    ${theme.queries.small} {
      right: 30px;

      img {
        width: 22px;
        height: 22px;
      }
    }
  `}
`

const Subtitle = styled.h2`
  ${({ theme }) => css`
    font-size: 36px;
    margin-bottom: 26px;
    margin-top: 30px;
    color: ${theme.colors.blue};
  `}
`

const CardGrid = styled.div`
  ${({theme, exiting }) => css`
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    flex: 0 0 31%;
    margin-bottom: 50px;
    margin-left: -14px;
    margin-right: -14px;
    transition: ease-in-out 500ms;

    ${exiting &&
css`
        transform: translateY(10%);
        opacity: 0;
    `}
    
    ${theme.queries.maxLarge} {
      flex: 0 0 48%;
    }
  `}
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

const animateFadeOut = css`
  position: relative;
  opacity: 1;
  animation: ${fadeOut} 500ms ease-in forwards;
  animation-delay: 200ms;
`

export {
    SearchInput,
    CardGrid,
    Subtitle,
    fadeOut,
    animateFadeOut,
    SearchButton
}