/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core"
import {Card,CampaignDetails,CampaignImage} from "./styles"
import PropTypes from "prop-types"

const MainCard = ({ item, onClick }) => (
  <Card onClick={() => onClick()}>
    <CampaignImage image={item.image} />
    <CampaignDetails>
      <h3>{item.title}</h3>
      <p>{item.description}</p>
    </CampaignDetails>
  </Card>
)

MainCard.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export default MainCard
