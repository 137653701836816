/** @jsx jsx */
import React, { useState, useEffect } from "react"
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"
import { connect } from "react-redux"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import Wizard from "../../components/Wizard"
import NoResults from "../../components/NoResults"
import Container from "../../components/Container"
import Card from "../../components/pages/Donate/MainCard"

import {
  setStep,
  getInstitutionCampaignsList,
  chooseCampaign,
  chooseDestination,
} from "../../state/app"
import Search from "../../assets/images/search.png"
import Loading from "../../assets/images/svg/loading.svg"
import { langNavigate } from "../../helpers"

const SearchInput = styled.input`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    padding: 30px 72px;
    border: none;
    border-radius: 10px;
    font-size: 28px;
    line-height: 33px;
    box-shadow: ${theme.shadows.default};
    margin-bottom: 67px;

    ${theme.queries.small} {
      font-size: 18pt;
      padding: 17px 30px;
    }
  `}
`

const SearchButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background: none;
    right: 70px;
    bottom: 50%;
    position: absolute;
    transform: translateY(50%);
    cursor: pointer;

    ${theme.queries.small} {
      right: 30px;

      img {
        width: 22px;
        height: 22px;
      }
    }
  `}
`

const Subtitle = styled.h2`
  ${({ theme }) => css`
    font-size: 36px;
    margin-bottom: 26px;
    margin-top: 30px;
    color: ${theme.colors.blue};
  `}
`

const CardGrid = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex: 0 0 31%;
  margin-bottom: 50px;
  margin-left: -14px;
  margin-right: -14px;
`

const Works = ({ dispatch, translation }) => {
  const [isLoading, toggleLoading] = useState(true)
  const [query, setQuery] = useState(null)
  const [workList, setWorkList] = useState([])

  useEffect(() => {
    dispatch(setStep(2))

    dispatch(getInstitutionCampaignsList())
      .then(({ data: resp }) => {
        setWorkList(
          resp.results.map(
            ({ imagePath, campaignName, institutionName, id }) => ({
              image: imagePath,
              title: campaignName,
              description: institutionName,
              id,
            })
          )
        )
        toggleLoading(false)
      })
      .catch(() => {
        setTimeout(() => {
          setWorkList([])
          toggleLoading(false)
        }, 1000)
      })
  }, [dispatch])

  const search = e => {
    e.preventDefault()

    toggleLoading(true)
    dispatch(getInstitutionCampaignsList(5757, 1, 9999, query))
      .then(({ data: resp }) => {
        setWorkList(
          resp.results.map(
            ({ imagePath, campaignName, institutionName, id }) => ({
              image: imagePath,
              title: campaignName,
              description: institutionName,
              id,
            })
          )
        )
        toggleLoading(false)
      })
      .catch(() => {
        setTimeout(() => {
          setWorkList([])
          toggleLoading(false)
        }, 1000)
      })
  }

  const setWorks = work => {
    dispatch(
      chooseDestination({
        title: "Templo de Salomão",
        image: "https://s3.us-east-1.amazonaws.com/appuniversal/19491ch",
        id: 5757,
        description: "São Paulo - São Paulo - BR",
      })
    )
    dispatch(
      chooseCampaign({
        imagePath: work.image,
        campaignName: work.title,
        id: work.id,
      })
    )

    langNavigate(6, translation.currentLang)
  }

  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title="Doar para obras sociais" />

      <Wizard
        translation={translation}
        title={translation.chooseWork}
        destination={translation.socialWorks}
      >
        <Subtitle>{translation.socialWork}</Subtitle>
        <form
          onSubmit={search}
          css={css`
            position: relative;
          `}
        >
          <SearchInput
            type="text"
            placeholder={translation.searchWork}
            onChange={e => setQuery(e.target.value)}
            value={query}
          />
          <SearchButton>
            <img src={Search} alt={translation.search} />
          </SearchButton>
        </form>

        <Subtitle>{translation.recentWorks}</Subtitle>
        <Container>
          <CardGrid>
            {isLoading && <Loading role="img" />}
            {!isLoading &&
              workList.length &&
              workList.map((item, index) => (
                <Card key={index} item={item} onClick={() => setWorks(item)} />
              ))}
            {!isLoading && !workList.length && (
              <NoResults translation={translation} />
            )}
          </CardGrid>
        </Container>
      </Wizard>
    </Layout>
  )
}

export default connect()(Works)
