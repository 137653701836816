/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { jsx, css } from "@emotion/core";

import { connect } from "react-redux";
import { toast } from "react-toastify";

import Layout from "../../../components/Layout";
import SEO from "../../../components/seo";
import Wizard from "../../../components/Wizard";

import Loading from "../../../assets/images/svg/loading.svg";
import Other from "../../../assets/images/other.png";

import {
  animateFade,
  animateFadeOut,
  Button,
  Campaign,
  Background,
  ButtonSelect,
  CampaignImage,
  CampaignsContainer,
  ChurchName,
  ContainerButton,
  Picture,
  Subtitle,
  CurrentChurch,  
} from "./style-campanha"

import {
  setStep,
  getInstitutionCampaigns,
  chooseCampaign,
} from "../../../state/app";
import { langNavigate } from "../../../helpers";


const CampaignIndex = ({ dispatch, churchSelected, translation }) => {
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [exiting, setExiting] = useState(false);
  const [activeButton, setActiveButton] = useState(0);
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns.filter(campaign => campaign.categoryType === 1));
 
  useEffect(() => {
    setFilteredCampaigns(campaigns.filter(campaign => campaign.categoryType === 1));
  }, [campaigns]);

  const handleButtonClick = (index, categoryType) => {
    setActiveButton(index);
    const filtered = campaigns.filter(campaign => campaign.categoryType === categoryType);
    setFilteredCampaigns(filtered);
  };

  useEffect(() => {
    dispatch(setStep(2));
    if (churchSelected.id) {
      dispatch(getInstitutionCampaigns(churchSelected.id))
        .then(({ data }) => {
          setCampaigns(data.results);
          setLoading(false);
        })
        .catch(() => {
          toast.error(translation.errorCampaign);
          setLoading(false);
        });
    } else {
      langNavigate(5, translation.currentLang);
    }
  }, [churchSelected, dispatch, getInstitutionCampaigns, translation]);

  const setCampaignDestination = (campaign) => {
    dispatch(chooseCampaign(campaign));
    langNavigate(
      6,
      translation.currentLang,
      () => {
        setExiting(true);
      },
      1000
    );
  };

  const filterAndDisplayCampaigns = (categoryType) => {
    return campaigns.filter(campaign => campaign.categoryType === categoryType)
      .map((campaign, index) => (
        <Campaign
          css={exiting ? animateFadeOut : animateFade}
          key={index}
          onClick={() => setCampaignDestination(campaign)}
        >
          <CampaignImage image={campaign.imagePath} />
          <p>{campaign.campaignName}</p>
        </Campaign>
      ));
  };

  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={churchSelected.title} />

      <Wizard translation={translation} destination={translation.campaigns}>
        {/* <Title css={exiting ? animateFadeOut : animateFade}>{translation.chooseCampaign}</Title> */}

        <CurrentChurch
          exiting={exiting}
          css={css`
            margin-top: 20px;
          `}
        >
          <Picture image={churchSelected.image} />
          <ChurchName>
            <p>
              <strong>{translation.church}</strong>
            </p>
            <br />
            <h1>{churchSelected.title}</h1>
            <p>{churchSelected.description}</p>
          </ChurchName>
          <Button onClick={() => langNavigate(9, translation.currentLang)}>
            {translation.change}
          </Button>
        </CurrentChurch>

        <Subtitle css={exiting ? animateFadeOut : animateFade}>
          {translation.donate.title}
        </Subtitle>

        <ContainerButton>
          <Background activeButton={activeButton} /> 
          <ButtonSelect 
            css={( ) => css`
              border-radius: 8px 0 0 8px;
          `}
            className={activeButton === 0 ? "active" : ""} 
            onClick={() => handleButtonClick(0, 1)}
          >
            {translation.campaigns}
          </ButtonSelect>
          <ButtonSelect
             css={( ) => css`
             border-radius: 0 8px 8px 0;
         `}
            className={activeButton === 1 ? "active" : ""}
            onClick={() => handleButtonClick(1, 2)}
          >
          {translation.groups}
          </ButtonSelect>
        </ContainerButton>

        <CampaignsContainer>
          {loading && <Loading role="img" />}

          {!loading && (
            <>
              {filteredCampaigns.map((campaign, index) => (
                <Campaign
                  css={exiting ? animateFadeOut : animateFade}
                  key={index}
                  onClick={() => setCampaignDestination(campaign)}
                >
                  <CampaignImage image={campaign.imagePath} />
                  <p>{campaign.campaignName}</p>
                </Campaign>
              ))}
              <Campaign
                css={exiting ? animateFadeOut : animateFade}
                onClick={() => setCampaignDestination({ id: "other" })}
              >
                <CampaignImage other image={Other} />
                <p>{translation.other}</p>
              </Campaign>
            </>
          )}
        </CampaignsContainer>
      </Wizard>
    </Layout>
  );
};

export default connect(
  (state) => ({
    churchSelected: state.app.donationDestination,
  }),
  null
)(CampaignIndex);
