import { css } from "@emotion/core"
import styled from "@emotion/styled"

export const Container = styled.div``

export const SelectionGroup = styled.div`
  ${({ theme, hidden }) => css`
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;

    ${hidden &&
      css`
        display: none;
      `}
  `}
`

export const Options = styled.div`
  ${({ theme }) => css`
    margin-top: 1rem;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    ${theme.queries.large} {
      grid-template-columns: repeat(5, 1fr);
    }
  `}
`

export const InputGroup = styled.div`
  ${({ theme, hidden }) => css`
    display: flex;
    align-items: center;

    img {
      width: 3rem;
      height: auto;
      margin-left: 1rem;
    }

    ${hidden &&
      css`
        display: none;
      `}
  `}
`
