import React from "react";
import { jsx, css, keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import Scan from "../../../../assets/images/svg/scan.svg";
import Pix from "../../../../assets/images/svg/pix.svg";
import Files from "../../../../assets/images/svg/files.svg";
import Smartphone from "../../../../assets/images/svg/smartphone.svg";
import CheckCircle from "../../../../assets/images/svg/check-circle.svg";
import {  isMobile  } from "../../../../helpers";

const Card = styled.div`
  display: flex;
  max-width: ${isMobile() ? 350: 400}px;
  height: ${isMobile() ? 450: 532}px;
  padding: 8px 22px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;

  border-radius: 12px;
  background: #f0f0f0;
`;

const Title = styled.p`
  display: flex;
  width: 356px;
  flex-direction: column;
  justify-content: center;

  color: #203760;
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.28px;
  margin-bottom: ${isMobile() ? 10: 0}px;

  @media (max-width: 1100px) { 
    font-size: 28px;
  }
`;

const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: ${isMobile() ? 10: 0}px;
`;

const Text = styled.span`
  display: flex;
  width: 323px;
  flex-direction: column;
  justify-content: center;

  color: var(--color-neutral-3, #919191);
  font-size: ${isMobile() ? 14: 16}px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 25px;

  @media (max-width: 1100px) { 
    max-width:  ${isMobile() ? 280: 323}px;
    font-size: ${isMobile() ? 14: 16}px;
  }
`;

const CardInfoPix = ({translation}) => {
  return (
    <Card>
      <Title>{translation.instructions}</Title>
      <ContentIcon>
        <Icon>
          <Scan />
        </Icon>
        <Text>{translation.selectAmount}</Text>
      </ContentIcon>
      <ContentIcon>
        <Icon>
          <Smartphone />
        </Icon>
        <Text>{translation.accessBank}</Text>
      </ContentIcon>
      <ContentIcon>
        <Icon>
          <Pix />
        </Icon>
        <Text>{translation.findPix}</Text>
      </ContentIcon>
      <ContentIcon>
        <Icon>
          <Files />
        </Icon>
        <Text>{translation.copyPixKey}</Text>
      </ContentIcon>
      <ContentIcon>
        <Icon>
          <CheckCircle />
        </Icon>
        <Text>{translation.pastePixKey}</Text>
      </ContentIcon>
    </Card>
  );
};

export default CardInfoPix;
