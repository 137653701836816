import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

export const Container = styled(motion.div)`
  ${({ theme }) => css`
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 23px 36px #8aa6d839;
    height: 260px;
    left: 0;
    opacity: 1;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;

    ${theme.queries.medium} {
      height: 140px;
      padding: 40px 20px;
    }

    div {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      ${theme.queries.small} {
        flex-direction: column;
        align-items: center;
      }

      ${theme.queries.medium} {
        max-width: 1200px;
      }
    }

    svg {
      ${theme.queries.small} {
        height: 40px;
        margin-bottom: 10px;
        width: 40px;
      }

      ${theme.queries.medium} {
        height: 60px;
        margin-right: 28px;
        width: 60px;
      }
    }

    p:first-child {
      color: #f8d050;
      font-size: 18px;
      line-height: 22px;

      ${theme.queries.medium} {
        font-size: 22px;
        line-height: 26px;
      }
    }

    p:last-child {
      color: #8b8b8b;
      font-size: 14px;
      line-height: 22px;

      ${theme.queries.small} {
        text-align: center;
      }

      ${theme.queries.medium} {
        font-size: 16px;
        line-height: 26px;
      }
    }
  `}
`
