/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from "@emotion/styled"

import Visa from "../../../assets/images/brands/visa.png"
import Mastercard from "../../../assets/images/brands/mastercard.png"
import Elo from "../../../assets/images/brands/elo.png"

const Container = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;

    p {
      color: ${theme.colors.blue};
      font-weight: bold;
    }

    div {
      display: flex;
      margin-top: 1rem;

      img {
        margin-right: 1rem;
      }
    }
  `}
`

const Banks = () => {
  return (
    <Container>
      <p>Bandeiras aceitas</p>
      <div>
        <img src={Visa} alt="" />
        <img src={Mastercard} alt="" />
        <img src={Elo} alt="" />
      </div>
    </Container>
  )
}

export default Banks
