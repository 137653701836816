import React, { useState, useRef , useEffect} from "react";
import {   css  } from "@emotion/core";
import styled from "@emotion/styled";
import DefaultButton from "../../../../components/Button"; 
import Copy from "../../../../assets/images/svg/copy.svg";
import {  isMobile  } from "../../../../helpers";
import QRCode from 'qrcode'


const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgQrCode = styled.img`
  display: flex;
  width: 220.951px;
  height: 218.346px;
  flex-shrink: 0;
`;

const ContentQrCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 30px;

  margin: 0 auto;
  margin-top:  ${isMobile() ? 20: 0}px;

  width: 240.491px;
  height: 240.583px;
  flex-shrink: 0;

  border-radius: 15.632px;
  border: 2.605px solid var(--color-primary-0, #2982cd);
  background: rgba(217, 217, 217, 0);
`;

const Text = styled.span`
  display: flex;
  width: 130px;
  height: 16.154px;
  flex-direction: column;
  justify-content: center;

  color: var(--neutral-gray-4, #6d6d6e);
  font-size: 12px;
  font-family: Roboto;
`;

const KeyText = styled.span`
  display: flex;
  width: 257px;
  height: 26.538px;
  flex-direction: column;
  justify-content: center;
 
  font-size: 20px;
  font-family: Roboto; 
  ${({ theme, isExpired }) => css`
    color: ${isExpired ? "#919191" : "#000"};
  `}
`;

const Box = styled.div`
  width: 290px;
  padding: 10px;
  flex-shrink: 0;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
  margin-bottom: 21px;

  border-radius: 4px;
  background: var(--neutral-gray-1, #f0f1f5);
`;

const Modal = styled.div` 
   background: #333333;
  padding: 10px;
  border-radius: 5px; 
  z-index: 999999999999;
  position: absolute;
  top: 80px;  
  width: 290px;  
  text-align: center;  
`;

const CustomButtonPix = styled(DefaultButton)`
  ${({ theme, disabled }) => css`
    text-transform: uppercase;
    font-size: 14px;
    height: 50px;
    position: relative;
    width: 290px;
    padding: 0;
    color: ${disabled ? "#919191" : "#fff"};
    position: relative; 

    background: ${disabled ? "#E2E2E2" : theme.colors.lightBlue};
    box-shadow: ${disabled
      ? `0px 4px 4px rgba(0, 0, 0, 0.25)`
      : `${theme.colors.lightBlue}`};

    svg {
      height: 50px;
      top: 5px;
      margin-right: 10px;
    }

    ${theme.queries.small} {
      width: 100%;
      margin-top: ${isMobile() ? 10: 30}px;
    }
  `}
`;

 

const QrCode = ({keyPix, isExpired, translation}) => {
  const [remainingSeconds, setRemainingSeconds] = useState(300);
  const [copied, setCopied] = useState(false);
 
  const canvasRef = useRef(null);

  useEffect(() => { 
    QRCode.toCanvas(canvasRef.current, keyPix,{
      color:{
        dark:  isExpired ? "#919191" : "#000",   
      }
    }, function (error) {
      if (error) console.error(error)
     
    })
  }, [keyPix, isExpired]);
 

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(keyPix);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);  
  }; 

  return (
    <Container class="qrcode-container">
    
      <ContentQrCode> 
         <canvas ref={canvasRef} id="canvas" style={{
          width: 180,
          height: 180,
          zIndex: 1
         }}>      </canvas> 
      </ContentQrCode>
   
      <Box>
        <Text>{translation.copyPixCode}</Text>
        <KeyText isExpired={isExpired}>{keyPix.substring(0, 22) + "..."}</KeyText>
      </Box>

   { !isExpired &&  <CustomButtonPix onClick={copyToClipboard}>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Copy />
            <span>{translation.copyCode}</span>
          </div>
        </div>
      {copied && <Modal>{translation.codeCopied}</Modal>}
      </CustomButtonPix>}
    </Container>
  );
};

export default QrCode;
