/** @jsx jsx */
import React, { useState, useEffect } from "react"
import { jsx, css, keyframes } from "@emotion/core"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { DebounceInput } from "react-debounce-input"

import Layout from "../../../components/Layout"
import SEO from "../../../components/seo"
import Wizard from "../../../components/Wizard"
import Container from "../../../components/Container"
import NoResults from "../../../components/NoResults"
import Card from "../../../components/pages/Donate/MainCard"
import DonationPower from "../../../components/pages/Home/DonationPower"

import { setStep, getChurchList, chooseDestination } from "../../../state/app"
import { langNavigate, scrollToTop } from "../../../helpers"
import Search from "../../../assets/images/search.png"
import Loading from "../../../assets/images/svg/loading.svg"

import {
  SearchInput,
  CardGrid,
  Subtitle,
  animateFadeOut,
  SearchButton
} from "./style"

const Churches = ({ dispatch, translation }) => {
  const [loading, toggleLoading] = useState(true)
  const [query, setQuery] = useState("")
  const [churchList, setChurchList] = useState([])
  const [exiting, setExitAnimation] = useState(false)
  const [autocomplete, setAutocomplete] = useState(false)

  useEffect(() => {
    dispatch(setStep(2))

    dispatch(getChurchList())
      .then(({ data }) => {
        setChurchList(
          data.results.map(({ imagePath, tradingName, city, id }) => ({
            id,
            image: imagePath,
            title: tradingName,
            description: city,
          }))
        )
        toggleLoading(false)
      })
      .catch(() => {
        setTimeout(() => {
          setChurchList([])
          toggleLoading(false)
        }, 1000)
      })
  }, [dispatch])

  const search = e => {
    if (e) {
      e.preventDefault()
    }

    toggleLoading(true)
    const address = document.getElementById("searchTextField").value
    setQuery(address)

    dispatch(getChurchList(15, 1, address))
    .then(({ data }) => {
      setChurchList(
        data.results.map(({ imagePath, tradingName, city, id }) => ({
          id,
          image: imagePath,
          title: tradingName,
          description: city,
        }))
      )
      toggleLoading(false)
    })
    .catch(() => {
      setTimeout(() => {
        setChurchList([])
        toggleLoading(false)
      }, 1000)
    })
  }

  const animationFunc = () => {
    scrollToTop()
    setExitAnimation(true)
  }

  const setDestination = place => {
    dispatch(chooseDestination(place))
    langNavigate(10, translation.currentLang, animationFunc, 1000)
  }

  const handleSearch = value => {
    setQuery(value)

    dispatch(getChurchList(15, 1, value))
    .then(({ data }) => {
      setChurchList(
        data.results.map(({ imagePath, tradingName, city, id }) => ({
          id,
          image: imagePath,
          title: tradingName,
          description: city,
        }))
      )
      toggleLoading(false)
    })
    .catch(() => {
      setTimeout(() => {
        setChurchList([])
        toggleLoading(false)
      }, 1000)
    })
  }

  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={translation.pageName} />

      <Wizard
        translation={translation}
        title={translation.chooseChurch}
        destination={translation.churchsName}
      >
        <Subtitle css={exiting ? animateFadeOut : null}>
          {translation.churchsName}
        </Subtitle>
        <div
          css={
            exiting
              ? animateFadeOut
              : css`
                  position: relative;
                `
          }
        >
          <form onSubmit={search}>
            <SearchInput
              id="searchTextField"
              type="text"
              minLength={2}
              debounceTimeout={800}
              placeholder={translation.searchChurch}
              value={query}
              onChange={event => handleSearch(event.target.value)}
            />
            <SearchButton>
              {<img src={Search} alt={translation.search} />}
            </SearchButton>
          </form>
        </div>

        <Subtitle css={exiting ? animateFadeOut : null}>
          {translation.nextToMe}
        </Subtitle>
        <Container>
          {loading && <Loading role="img" />}
          {!loading && !churchList.length && (
            <NoResults translation={translation} />
          )}
          <CardGrid exiting={exiting}>
            {!loading &&
              churchList.map((item, index) => (
                <Card
                  key={index}
                  item={item}
                  onClick={() => setDestination(item)}
                  translation={translation}
                />
              ))}
          </CardGrid>
        </Container>
      </Wizard>
      <DonationPower fontDefault={true} translation={translation} />
    </Layout>
  )
}

export default connect()(Churches)
